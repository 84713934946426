<template>
    <BaseContainer>
        <span slot="principal">
            <v-content :style="`background-color: #ddd;`">
                <v-toolbar
                    class="my-2 mx-4"
                    style="border-radius: 30px; border: 1px solid #ddd;"
                >
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title>Relatório Gráfico</v-toolbar-title>
                    <v-spacer/>
                    <PeriodoFilter
                        v-model="periodo"
                        @update="consultar"
                    />
                </v-toolbar>
                <v-container fluid class="px-4" :style="`height: ${$vuetify.breakpoint.height - 150}px; overflow-x: auto;`">
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-card class="mx-auto" elevation="4" outlined style="display: flex; align-items: center;">
                                <v-list-item three-line>
                                    <v-list-item-avatar tile :color="getColors()[0]" size="70" rounded class="br-5">
                                        <v-icon x-large color="white">mdi-account</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <div class="text-overline subtitle-2 mb-2">
                                            Clientes
                                        </div>
                                        <v-list-item-title class="monetary mb-1">
                                            {{ dados.totalClientes || 0 }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <div class="subtitle-2" style="width: 150px; color: green;">
                                    + {{ dados.clientesNovos || 0 }} Hoje
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-card class="mx-auto" elevation="4" outlined style="display: flex; align-items: center;">
                                <v-list-item three-line>
                                    <v-list-item-avatar tile :color="getColors()[1]" size="70" rounded class="br-5">
                                        <v-icon x-large color="white">mdi-cash</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <div class="text-overline subtitle-2 mb-2">
                                            Ticket Médio
                                        </div>
                                        <v-list-item-title class="monetary mb-1">
                                            R$ {{ formatPrice(dados.ticketMedio || 0) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <!-- <div class="subtitle-2" style="width: 200px; color: green;">
                                    +10,00% que ontem
                                </div> -->
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-card class="mx-auto" elevation="4" outlined style="display: flex; align-items: center;">
                                <v-list-item three-line>
                                    <v-list-item-avatar tile :color="getColors()[2]" size="70" rounded class="br-5">
                                        <v-icon x-large color="white">mdi-bike-fast</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <div class="text-overline subtitle-2 mb-2">
                                            Taxas de Entrega
                                        </div>
                                        <v-list-item-title class="monetary mb-1">
                                            R$ {{ formatPrice(dados.totalFretes || 0) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-card class="mx-auto" elevation="4" outlined style="display: flex; align-items: center;">
                                <v-list-item three-line>
                                    <v-list-item-avatar tile :color="getColors()[4]" size="70" rounded class="br-5">
                                        <v-icon x-large color="white">mdi-note-edit</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <div class="text-overline subtitle-2 mb-2">
                                            Pedidos
                                        </div>
                                        <v-list-item-title class="monetary mb-1">
                                            {{ (dados.quantidadePedidos || 0) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-card class="mx-auto" elevation="4" outlined style="display: flex; align-items: center;">
                                <v-list-item three-line>
                                    <v-list-item-avatar tile :color="getColors()[5]" size="70" rounded class="br-5">
                                        <v-icon x-large color="white">mdi-calendar</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <div class="text-overline subtitle-2 mb-2">
                                            Agendamentos
                                        </div>
                                        <v-list-item-title class="monetary mb-1">
                                            {{ (dados.quantidadeAgendamentos || 0) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-card class="mx-auto" elevation="4" outlined style="display: flex; align-items: center;">
                                <v-list-item three-line>
                                    <v-list-item-avatar tile :color="getColors()[8]" size="70" rounded class="br-5">
                                        <v-icon x-large color="white">mdi-sale</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <div class="text-overline subtitle-2 mb-2">
                                            Cupons Utilizados
                                        </div>
                                        <v-list-item-title class="monetary mb-1">
                                            {{ (dados.cuponsUtilizados || 0) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-card class="mx-auto" elevation="4" outlined style="display: flex; align-items: center;">
                                <v-list-item three-line>
                                    <v-list-item-avatar tile :color="getColors()[3]" size="70" rounded class="br-5">
                                        <v-icon x-large color="white">mdi-currency-usd</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <div class="text-overline subtitle-2 mb-2">
                                            Total Vendas
                                        </div>
                                        <v-list-item-title class="monetary mb-1">
                                            R$ {{ formatPrice(dados.totalVendas || 0) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-card class="mx-auto" elevation="4" outlined style="display: flex; align-items: center;">
                                <v-list-item three-line>
                                    <v-list-item-avatar tile :color="getColors()[6]" size="70" rounded class="br-5">
                                        <v-icon x-large color="white">mdi-credit-card-fast-outline</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <div class="text-overline subtitle-2 mb-2">
                                            Total Custos
                                        </div>
                                        <v-list-item-title class="monetary mb-1">
                                            R$ {{ formatPrice(dados.totalCustos || 0) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-card class="mx-auto" elevation="4" outlined style="display: flex; align-items: center;">
                                <v-list-item three-line>
                                    <v-list-item-avatar tile :color="getColors()[7]" size="70" rounded class="br-5">
                                        <v-icon x-large color="white">mdi-finance</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <div class="text-overline subtitle-2 mb-2">
                                            Total Lucro
                                        </div>
                                        <v-list-item-title class="monetary mb-1">
                                            R$ {{ formatPrice((dados.totalVendas - dados.totalCustos) || 0) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-card class="mx-auto" elevation="4" outlined style="display: flex; align-items: center;">
                                <v-list-item three-line>
                                    <v-list-item-avatar tile :color="getColors()[9]" size="70" rounded class="br-5">
                                        <v-icon x-large color="white">mdi-cart-plus</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <div class="text-overline subtitle-2 mb-2">
                                            Venda Complementar
                                        </div>
                                        <v-list-item-title class="monetary mb-1">
                                            R$ {{ formatPrice(dados.totalOrderBump || 0) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    (R$) Top 10 Produtos mais Vendidos
                                </div>
                                <canvas id="produtosMaisVendidos" :height="pieChartHeight"></canvas>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    (R$) Faturamento por Grupo
                                </div>
                                <canvas id="faturamentoGrupo" :height="pieChartHeight"></canvas>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    (Quantidade) Top 10 Produtos mais Vendidos
                                </div>
                                <canvas id="produtosMaisVendidosQtd" :height="pieChartHeight"></canvas>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    Quantidade Vendida por Grupo
                                </div>
                                <canvas id="quantidadeVendidaGrupo" :height="pieChartHeight"></canvas>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    Top 10 Clientes
                                </div>
                                <v-data-table
                                    fixed-header
                                    :headers="headers.totais"
                                    :items="dados.topClientes"
                                    sort-by="id"
                                    dense
                                    :mobileBreakpoint="0"
                                    height="200"
                                    :disable-pagination="true"
                                    :hide-default-footer="true"
                                >
                                </v-data-table>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    Pedidos x Forma de Pagamento
                                </div>
                                <v-data-table
                                    fixed-header
                                    :headers="headers.pedidosFormaPagamento"
                                    :items="dados.pedidosFormaPgto"
                                    sort-by="id"
                                    dense
                                    :mobileBreakpoint="0"
                                    height="200"
                                    :disable-pagination="true"
                                    :hide-default-footer="true"
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    Complementos mais Vendidos
                                    <v-spacer />
                                    <v-select
                                        v-model="complementoSelected"
                                        :items="complementosOptions"
                                        :item-text="e => `${e.value} - ${e.text}`"
                                        placeholder="Filtro"
                                        clearable
                                    />
                                </div>
                                <v-data-table
                                    fixed-header
                                    :headers="headers.complementosMaisVendidos"
                                    :items="complementosMaisVendidos"
                                    sort-by="id"
                                    dense
                                    :mobileBreakpoint="0"
                                    height="200"
                                    :disable-pagination="true"
                                    :hide-default-footer="true"
                                >
                                    <template v-slot:item.passo="{ item }">
                                        <span class="mr-1">{{ item.passo_id }} - {{ item.passo }}</span>
                                        <v-chip v-if="item.order_bump" color="primary" x-small>
                                            Order Bump
                                        </v-chip>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    Fretes x Entregador
                                </div>
                                <v-data-table
                                    fixed-header
                                    :headers="headers.totais"
                                    :items="dados.fretesEntregador"
                                    sort-by="id"
                                    dense
                                    :mobileBreakpoint="0"
                                    height="270"
                                    :disable-pagination="true"
                                    :hide-default-footer="true"
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    Venda Complementar
                                </div>
                                <v-data-table
                                    fixed-header
                                    :headers="headers.totais"
                                    :items="dados.vendaComplementar"
                                    sort-by="id"
                                    dense
                                    :mobileBreakpoint="0"
                                    height="270"
                                    :disable-pagination="true"
                                    :hide-default-footer="true"
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="mx-auto" elevation="4" outlined>
                                <div class="text-overline subtitle-2 ma-2" style="text-align: center;">
                                    Faturamento Diário ({{ getCurrentMonthName }})
                                </div>
                                <canvas id="faturamentoDia" :height="barChartHeight"></canvas>
                                <!-- <canvas
                                    id="chartFaturamentoDia"
                                    :width="$vuetify.breakpoint.width - 80"
                                    height="300"
                                ></canvas> -->
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer'
import PeriodoFilter from '@/components/PeriodoFilter';
import Chart from 'chart.js';
import { getColors } from '@/utils/colors';

export default {
    name: 'Graficos',

    components: { 
        BaseContainer,
        PeriodoFilter,
    },

    data: () => ({
        loading: false,
        menu: false,
        headers: {
            totais: [
                { text: 'Nome', value: 'nome' },
                { text: 'Qtd. Pedidos', value: 'quantidade' },
                { text: '(R$) Total', value: 'total' },
            ],
            pedidosFormaPagamento: [
                { text: 'Forma Pgto', value: 'forma_pagamento' },
                { text: 'Qtd. Pedidos', value: 'quantidade' },
                { text: '(R$) Total', value: 'total' },
            ],
            complementosMaisVendidos: [
                { text: 'Etapa', value: 'passo' },
                { text: 'Complemento', value: 'complemento' },
                { text: 'Quantidade', value: 'quantidade' },
            ],
        },
        // tiposEntrega: [
        //     {
        //         id: 1,
        //         descricao: 'Retirada',
        //     },
        //     {
        //         id: 2,
        //         descricao: 'Delivery',
        //     },
        //     {
        //         id: 3,
        //         descricao: 'Local',
        //     },
        // ],
        pieCharts: {
            produtosMaisVendidos: null,
            faturamentoGrupo: null,
            produtosMaisVendidosQtd: null,
            quantidadeVendidaGrupo: null,
        },
        barCharts: {
            faturamentoDia: null
        },
        complementosOptions: [],
        complementoSelected: null,
        form: {},
        dados: [],
        periodo: {},
    }),

    mounted() {
        this.form.start = this.moment().startOf('month').format('DD/MM/YYYY HH:mm');
        this.form.end = this.moment().endOf('month').format('DD/MM/YYYY HH:mm');

        this.mountCharts();

        this.consultar();
    },

    computed: {
        complementosMaisVendidos() {
            return this.complementoSelected
                ? (this.dados.complementosMaisVendidos || [])
                    .filter(e => e.passo_id == this.complementoSelected)
                : this.dados.complementosMaisVendidos;
        },

        pieChartHeight() {
            const w = this.$vuetify.breakpoint.width;
            if (w <= 576) {
                return '400';
            }
            if (w > 576 && w <= 768) {
                return '300';
            }
            if (w > 768 && w <= 992) {
                return '250';
            }
            if (w > 992) {
                return '200';
            }
            return '200';
        },

        barChartHeight() {
            const w = this.$vuetify.breakpoint.width;
            if (w <= 576) {
                return '350';
            }
            if (w > 576 && w <= 768) {
                return '250';
            }
            if (w > 768 && w <= 992) {
                return '200';
            }
            if (w > 992) {
                return '100';
            }
            return '100';
        },

        getCurrentMonthName() {
            return this.capitalizeFirstLetter(this.moment().format('MMMM'));
        },
    },

    methods: {
        consultar() {
            if (!this.periodo.start || !this.periodo.end) {
                this.notify('Informe o período', 'warning');
                return;
            }

            this.menu = false;

            const start = this.periodo.start;
            const end = this.periodo.end;
            // const types = this.form.tipoEntrega?.map(e => e.id).join(',');
            const types = '';

            this.$root.$emit('loading', true);
            const params = `start=${start}&end=${end}&types=${types}`;
            this.$http.get(`relatorio-graficos?${params}`).then(resp => {
                this.dados = resp.data;
                this.gerarGrafico();
            })
            .finally(() => this.$root.$emit('loading', false));
        },

        gerarGrafico() {
            const produtosMaisVendidos = this.dados.produtosMaisVendidos;
            this.pieCharts.produtosMaisVendidos.data = {
                labels: produtosMaisVendidos.map(p => p.descricao),
                datasets: [
                    {
                        data: produtosMaisVendidos.map(p => p.total),
                        backgroundColor: getColors(),
                    }
                ]
            };
            this.pieCharts.produtosMaisVendidos.update();

            const faturamentoGrupo = this.dados.faturamentoGrupo;
            this.pieCharts.faturamentoGrupo.data = {
                labels: faturamentoGrupo.map(p => p.descricao),
                datasets: [
                    {
                        data: faturamentoGrupo.map(p => p.total),
                        backgroundColor: getColors(),
                    }
                ]
            };
            this.pieCharts.faturamentoGrupo.update();

            const produtosMaisVendidosQtd = this.dados.produtosMaisVendidos;
            this.pieCharts.produtosMaisVendidosQtd.data = {
                labels: produtosMaisVendidosQtd.map(p => p.descricao),
                datasets: [
                    {
                        data: produtosMaisVendidosQtd.map(p => p.quantidade),
                        backgroundColor: getColors(),
                    }
                ]
            };
            this.pieCharts.produtosMaisVendidosQtd.update();

            const quantidadeVendidaGrupo = this.dados.faturamentoGrupo;
            this.pieCharts.quantidadeVendidaGrupo.data = {
                labels: quantidadeVendidaGrupo.map(p => p.descricao),
                datasets: [
                    {
                        data: quantidadeVendidaGrupo.map(p => p.quantidade),
                        backgroundColor: getColors(),
                    }
                ]
            };
            this.pieCharts.quantidadeVendidaGrupo.update();

            const faturamentoDia = this.dados.faturamentoDia;
            this.barCharts.faturamentoDia.data = {
                labels: faturamentoDia.map(p => `${this.moment(p.dia).format('dddd')} (${p.ddmm})`),
                datasets: [
                    {
                        label: this.getCurrentMonthName,
                        data: faturamentoDia.map(p => p.total),
                        backgroundColor: Array.from({length: 31}, () => '#2ECC71'),
                    }
                ]
            };
            this.barCharts.faturamentoDia.update();

            const selectOptions = [];
            this.dados.complementosMaisVendidos.forEach(e => {
                let notInclude = !selectOptions.find(i => i.value == e.passo_id);
                notInclude && selectOptions.push({
                    value: e.passo_id,
                    text: e.passo,
                })
            })

            this.complementosOptions = selectOptions;
        },

        mountCharts() {
            // TODO: Ajustar para declarar pieCharts em loop
            const common = { type: 'pie', data: { labels: [], datasets: [] }};
            const $ = el => document.getElementById(el);
            this.pieCharts.produtosMaisVendidos = new Chart($('produtosMaisVendidos'), {
                ...common,
                options: this.getPieChartOptions(true)
            });

            this.pieCharts.faturamentoGrupo = new Chart($('faturamentoGrupo'), {
                ...common,
                options: this.getPieChartOptions(true)
            });

            this.pieCharts.produtosMaisVendidosQtd = new Chart($('produtosMaisVendidosQtd'), {
                ...common,
                options: this.getPieChartOptions()
            });

            this.pieCharts.quantidadeVendidaGrupo = new Chart($('quantidadeVendidaGrupo'), {
                ...common,
                options: this.getPieChartOptions()
            });

            this.barCharts.faturamentoDia = new Chart($('faturamentoDia'), {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: []
                },
                options: {
                    // responsive: false,
                    // maintainAspectRatio: false,
                   scales: {
                        y: { beginAtZero: true },
                        yAxes: [
                            {
                                ticks: {
                                    callback: v => 'R$ ' + this.formatPrice(v)
                                },
                            }
                        ]
                    },
                    tooltips: {
                        callbacks: {
                            label: v => 'R$ ' + this.formatPrice(v.yLabel)
                        }
                    },
                }
            });
        },

        getPieChartOptions(formatPrice = false) {
            return {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    position: this.$vuetify.breakpoint.mdAndDown ? 'top' : 'right',
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            if (formatPrice) {
                                return 'R$ ' + this.formatPrice(value);
                            }
                            return value;
                        }
                    }
                }
            }
        },

        getColors() {
            return getColors();
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    }
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}

.br-5 {
    border-radius: 5px !important;
}

/* CARDS  */
.class-on-data-table table {
    table-layout: fixed;
}

.br-5 {
    border-radius: 5px !important;
}

.monetary {
    font-weight: 600;
    font-size: 26px;
}

.col-sm-6  {
    padding: 5px;
}

.col-12 {
    padding: 5px 10px 5px 10px;
}

.v-card {
    padding: 0 15px 0 15px;
}

.text-overline {
    font-weight: 600;
    letter-spacing: 0.1em !important;
}
</style>
